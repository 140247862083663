import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebsocketService } from './shared/services/websocket.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private http: HttpClient) {
    }

    general: JSON;

    websocketService: WebsocketService;

    ngOnInit() {
        // Read general settings file
        // Write to localStorage
        this.http.get('assets/settings/general')
        .subscribe(data => {
            this.general = data as JSON;

            localStorage.setItem("backend-ip", this.general['backend-ip']);
            localStorage.setItem("backend-port", this.general['backend-port']);
            localStorage.setItem("logsocket-ip", this.general['logsocket-ip']);
            localStorage.setItem("logsocket-port", this.general['logsocket-port']);
        });

        // Remove logged in state on starting app
        localStorage.removeItem('isLoggendin');
    }
}
